/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Cliente, Usuario } from '../interfaces/interfaces';
import { NavController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Route, Router } from '@angular/router';
import OneSignal from 'onesignal-cordova-plugin';

const URL = environment.url;

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

 token: string = null;
 private usuario: Cliente = {};

  constructor( private http: HttpClient,
               private storage: Storage,
               private platform: Platform,
               private router: Router,
               private navCtrl: NavController ) { }


  public login( email: string, password: string ) {

    const data = { email, password };

    return new Promise( resolve => {
      this.http.post(`${ URL }/cliente/login`, data )
        .subscribe( async resp => {
          if ( resp['ok'] ) {
            await this.guardarToken( resp['token'] );
            this.usuario = resp['usuario'];
            resolve(true);
          } else {
            this.token = null;
            this.storage.clear();
            resolve(false);
          }

        }, err => {
          console.log(err.toString());
        });

    }).catch( err => {
      console.log(err.toString());
    });;

  }

  logout() {
    this.token   = null;
    this.usuario = null;
    this.storage.clear();
    this.navCtrl.navigateRoot('/login', { animated: true });
  }

  getUsuario() {
    if ( !this.usuario || !this.usuario._id ) {
      this.validaToken();
    }

    return { ...this.usuario };

  }


  async guardarToken( token: string ) {

    this.token = token;
    await this.storage.set('token', token);

    // await this.validaToken();


  }

  async cargarToken() {

    this.token = await this.storage.get('token') || null;

  }


  async validaToken(): Promise<boolean> {

    await this.cargarToken();

    if ( !this.token ) {
      this.navCtrl.navigateRoot('/login');
      return Promise.resolve(false);
    }


    return new Promise<boolean>( resolve => {

      const headers = new HttpHeaders({
        'x-token': this.token
      });

      this.http.get(`${ URL }/cliente/user`, { headers })
        .subscribe( async resp => {
          if ( resp['ok'] ) {
            this.usuario = resp['usuario'];
            const cambiarPassword = await this.checkIfCHangePassword(this.usuario._id);
            if (cambiarPassword) {
              this.logout();
            } else {
              if(!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
                const pushId = await this.storage.get('pushId');
                if (!pushId || pushId === '') {
                    this.updatePushId();
                }
              }
              resolve(true);
            }
          } else {
            this.navCtrl.navigateRoot('/login');
            resolve(false);
          }

        });


    });

  }

  public changePassword(cliente: Cliente, ) {
    const headers = new HttpHeaders({
      'x-token': this.token
    });
    return new Promise( resolve => {
      this.http.post(`${ URL }/cliente/changePassword`, cliente, {headers})
        .subscribe( async resp => {
          resolve(true);
      });
    });
  }

  public checkIfCHangePassword(usuarioId: string) {
    const headers = new HttpHeaders({
      'x-token': this.token
    });
    return new Promise( resolve => {
      this.http.get<any>(`${ URL }/cliente/checkPassword/${ usuarioId }`, {headers}).subscribe(res => {
        if (res['ok']) {
          resolve(res['cambioPassword']);
        } else {
          resolve(false);
        }
      });
    });
  }

  public getToken(): Promise<string> {
    return this.storage.get('token');
  }

  private updatePushId() {
    const headers = new HttpHeaders({
      'x-token': this.token
    });
    OneSignal.getDeviceState((state) => {
        this.usuario.pushId = state.userId;
        this.http.post(`${ URL }/cliente/updatePushId`, this.usuario, {headers})
          .subscribe( async resp => {
            if (resp['ok']) {
              this.storage.set('pushId', state.userId);
            }
      });
    });
  }

}
