/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Component } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import OneSignal from 'onesignal-cordova-plugin';
import { UiServiceService } from './services/ui-service.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    protected platform: Platform,
    protected navController: NavController
    ) {
    this.platform.ready().then(() => {
      this.initPushNotification();
    });
  }

  private initPushNotification() {
    OneSignal.setAppId('e525f4f5-959f-4a8e-a60a-70defe384b29');
    OneSignal.setNotificationOpenedHandler(function(jsonData) {
        console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    });
    // Prompts the user for notification permissions.
    OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
        console.log('User accepted notifications: ' + accepted);
    });
  }
}
