/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Bono } from '../interfaces/interfaces';
import { ClienteService } from './cliente.service';

const URL = environment.url;


@Injectable({
  providedIn: 'root'
})
export class BonoService {


  constructor( private http: HttpClient,
              private usuarioService: ClienteService) { }

  public getClientes() {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Bono[]>(`${ URL }/bono`, {headers});
  }


  public addOrUpdateBono(bono: Bono) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    if (bono._id && bono._id !== '') {
      if (bono.tipo !== '1') {
        bono.zonas = '';
      }
      this.actualizarBono(bono, headers);
    } else {
      bono.sesiones_pendientes = bono.sesiones;
      this.crearBono(bono, headers);
    }
  }

  public getBonosByCliente(clienteId: string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Bono[]>(`${ URL }/bono/${ clienteId }`, {headers});
  }

  public getBonosActivosByClienteTipo(clienteId: string, tipo: string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Bono[]>(`${ URL }/bono/activo/${ clienteId }/${ tipo }`, {headers});
  }

  public getBonosNoFinalizadosByCliente(clienteId: string) {
    // eslint-disable-next-line arrow-body-style
    return this.getBonosByCliente(clienteId).pipe(
    );

    /*(bonos => {
      const bonosActivos = bonos.filter(b => b.estado === BonoConstants.ACTIVO);
      return bonosActivos;
    });*/
  }

  public eliminarBono(bonoId: string) {
    return new Promise( resolve => {
      const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
      });
      return this.http.get<Bono>(`${ URL }/bono/delete/${ bonoId }`, {headers}).subscribe(resp =>{
        console.log(resp);
        return resolve(true);
      });
    });

  }

  public getBonoById(bonoId: string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Bono>(`${ URL }/bono/bono/${ bonoId }`, {headers});
  }

  private crearBono( bono: Bono, headers: HttpHeaders) {
    return new Promise( resolve => {
      this.http.post(`${ URL }/bono/create`, bono, {headers})
        .subscribe( resp => {
          console.log('RESPUESTA', resp);
          resolve(true);
        });
    });
  }

  private actualizarBono( bono: Bono, headers: HttpHeaders) {
    return new Promise( resolve => {
      this.http.post(`${ URL }/bono/update`, bono, {headers})
        .subscribe( resp => {
          resolve(true);
      });
    });
  }

  /*public getSesionesPendientesByBono(bono: Bono) {
      const headers = new HttpHeaders({
        'x-token': this.usuarioService.token
      });
      return this.http.get<Bono>(`${ URL }/cita/sesiones/${ bono._id }`, {headers});
  }*/
}
