/* eslint-disable no-underscore-dangle */
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActionSheetController, AlertController, ModalController } from '@ionic/angular';
import { Bono, Cita, Cliente, Propuesta, Servicio, Zona } from 'src/app/interfaces/interfaces';
import { CitaService } from 'src/app/services/cita.service';
import { BonoConstants } from '../bono/bono.constant';
import { citasEstadosConstants } from './citas.estados.constants';
import * as moment from 'moment';
import { BonoService } from 'src/app/services/bono.service';

@Component({
  selector: 'app-cita',
  templateUrl: './cita.component.html',
  styleUrls: ['./cita.component.scss'],
})
export class CitaComponent implements OnInit {

  @Input()
  cita: Cita;

  @Input()
  //bono: Bono;

  @Input()
  cliente: Cliente;

  @Input()
  isModal = false;

  @Input()
  showNombreBono = false;

  @Output()
  returEvento = new EventEmitter<string>();

  public buttons: any[] = [];

  public nombreServicio = '';

  public color = '';

  public arrayEstadosCitas = citasEstadosConstants.arrayEstadosCitas;

  public estadoActual = '';

  public propuestas: Propuesta[] = [];

  constructor(public actionSheetController: ActionSheetController,
    private citaService: CitaService,
    private datePipe: DatePipe,
    private alertController: AlertController) { }

  ngOnInit() {
    this.getButonsByEstado();
    this.nombreServicio = (this.cita.servicio as Servicio)?.nombre;
    if (this.cita.estado === citasEstadosConstants.PTE_PROPUESTA) {
      this.propuestas = JSON.parse(this.cita.proposiciones);
    }
  }

  public convertirZona(zonas) {
    const zonarray: string[] = JSON.parse(zonas);
    return zonarray.toString().replace(/,/g, ', ');
  }

  async menu() {
    const actionSheet = await this.actionSheetController.create({
      header: 'CITA',
      cssClass: 'my-custom-class',
      buttons: this.buttons
    });
    if (this.cita.estado === citasEstadosConstants.PROGRAMADA ||
      this.cita.estado === citasEstadosConstants.SOLICITUD) {
      await actionSheet.present();
    }

    const { role } = await actionSheet.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  public async seleccionarPropuesta(pro: Propuesta) {
    const alert = await this.alertController.create({
      message: this.datePipe.transform(pro.fecha, 'dd/MM/yyyy HH:mm'),
      header: '¿Quiere seleccionar la siguiente cita?',
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'NO',
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'SI',
          cssClass: 'alert-button-confirm',
          handler: () => {
            this.actualizarCitaPropuesta(pro);
          }
        },
      ],
    });

    await alert.present();
  }

  private getButonsByEstado() {
    this.buttons = [];

    if (this.cita.estado === citasEstadosConstants.PROGRAMADA) {
        this.buttons.push({
          text: 'Solicitar Cambio',
          icon: 'checkmark',
          handler: () => {
            this.cita.revision = false;
            this.cambiarEstado(citasEstadosConstants.CAMBIO);
            this.returEvento.emit('recargarCitas');
          },
        });
        this.buttons.push({
          text: 'Cancelar cita',
          icon: 'close',
          handler: () => {
            this.cita.revision = false;
            this.cambiarEstado(citasEstadosConstants.CANCELADA);
            this.returEvento.emit('recargarCitas');
          },
        });
    }
    if (this.cita.estado === citasEstadosConstants.SOLICITUD) {
      this.buttons.push({
        text: 'Eliminar cita',
        icon: 'close',
        handler: () => {
          this.cita.revision = false;
          this.presentAlertEliminarCita();
        },
      });
    }
  }

  private cambiarEstado(estado: string): void {
    this.cita.estado = estado;
    if(this.cita.bono && this.cita.bono !== '') {
      this.citaService.addOrUpdateCita(this.cita, this.cita.bono as Bono);
    } else {
      this.citaService.actualizarCitaSimple(this.cita);
    }
    this.getButonsByEstado();
    // this.chargerColorStatus();
  }

  private async presentAlertEliminarCita() {
    const alert = await this.alertController.create({
      header: '¿Quiére eliminar la cita solicitada?',
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'NO',
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'SI',
          cssClass: 'alert-button-confirm',
          handler: () => {
            this.citaService.eliminarCita(this.cita._id);
            this.returEvento.emit('recargarCitas');
          }
        },
      ],
    });

    await alert.present();
  }

  private actualizarCitaPropuesta(prop: Propuesta) {
      this.cita.estado = citasEstadosConstants.PROGRAMADA;
      this.cita.fecha = prop.fecha;
      this.cita.empleado = prop.empleado._id;
      this.cita.proposiciones = '';
      this.citaService.actualizarCitaConProposicion(this.cita).then(()=>{
        this.returEvento.emit('recargarCitas');
      });
  }

}
