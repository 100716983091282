/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Bono, Cita, Zona } from '../interfaces/interfaces';
import { citasEstadosConstants } from '../components/cita/citas.estados.constants';
import { BonoService } from './bono.service';
import { BonoConstants } from '../components/bono/bono.constant';
import { ClienteService } from './cliente.service';

const URL = environment.url;


@Injectable({
  providedIn: 'root'
})
export class CitaService {


  constructor( private http: HttpClient,
              private usuarioService: ClienteService,
              private bonoService: BonoService) { }


  public addOrUpdateCita(cita: Cita, bono: Bono) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    if (cita._id && cita._id !== '') {
      this.actualizarCita(cita, headers);
    } else {
      cita.estado = citasEstadosConstants.SOLICITUD;
      cita.revision = false;
      this.crearCita(cita, headers);
    }
    // if (bono.tipo === '1') {
    //   this.recarcularCitasPendientesLaser(bono);
    // } else {
    //   this.recarcularCitasPendientes(bono);
    // }
  }

  public getCitasByBono(citaId: string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Cita[]>(`${ URL }/cita/${ citaId }`, {headers});
  }

  public getCitasByCliente(clienteId: string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Cita[]>(`${ URL }/cita/cliente/${ clienteId }`, {headers});
  }

  public eliminarCita(citaId: string) {
    return new Promise( resolve => {
      const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
      });
      return this.http.get<Cita>(`${ URL }/cita/delete/${ citaId }`, {headers}).subscribe(resp =>resolve(true));
    });

  }

  public crearCitaSimple( cita: Cita) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return new Promise( resolve => {
      this.http.post(`${ URL }/cita/create`, cita, {headers})
        .subscribe( resp => {
          resolve(true);
        });
    });
  }
  public actualizarCitaSimple( cita: Cita) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return new Promise( resolve => {
      this.http.post(`${ URL }/cita/update`, cita, {headers})
        .subscribe( resp => {
          resolve(true);
      });
    });
  }

  public actualizarCitaConProposicion( cita: Cita) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return new Promise( resolve => {
      this.http.post(`${ URL }/cita/updateProposition`, cita, {headers})
        .subscribe( resp => {
          resolve(true);
      });
    });
  }

  private crearCita( cita: Cita, headers: HttpHeaders) {
    return new Promise( resolve => {
      this.http.post(`${ URL }/cita/create`, cita, {headers})
        .subscribe( resp => {
          resolve(true);
        });
    });
  }

  private actualizarCita( cita: Cita, headers: HttpHeaders) {
    return new Promise( resolve => {
      this.http.post(`${ URL }/cita/update`, cita, {headers})
        .subscribe( resp => {
          resolve(true);
      });
    });
  }

  private recarcularCitasPendientes(bono: Bono) {
    this.getCitasByBono(bono._id).subscribe(resp=> {
      const citas: Cita[] = resp['citas'];
      const citasRest: Cita[] = citas.filter(c=>
        c.estado === citasEstadosConstants.PROGRAMADA ||
        c.estado === citasEstadosConstants.CAMBIO ||
        (c.estado === citasEstadosConstants.ACUDIDA && c.revision === false) ||
        c.estado === citasEstadosConstants.PTE_VALIDAR
      );
      const totalPendiente = bono.sesiones - citasRest.length;
      bono.sesiones_pendientes = totalPendiente;
      if (totalPendiente === 0) {
        bono.estado = BonoConstants.FINALIZADO;
      } else {
        bono.estado = BonoConstants.ACTIVO;
      }
      this.bonoService.addOrUpdateBono(bono);
    });
  }

  private recarcularCitasPendientesLaser(bono: Bono) {
    this.getCitasByBono(bono._id).subscribe(resp=> {
      const citas: Cita[] = resp['citas'];
      const citasRest: Cita[] = citas.filter(c=>
        c.estado === citasEstadosConstants.PROGRAMADA ||
        c.estado === citasEstadosConstants.CAMBIO ||
        (c.estado === citasEstadosConstants.ACUDIDA && c.revision === false) ||
        c.estado === citasEstadosConstants.PTE_VALIDAR
      );
      const zonasArr: Zona[] = JSON.parse(bono.zonas);
      zonasArr.forEach(z => {
        z.sesiones = bono.sesiones;
      });
      citasRest.forEach(cita => {
        const zonasCita: string[] = JSON.parse(cita.zonas);
        zonasCita.forEach(zonacita => {
          const indexZona = zonasArr.findIndex((zona => zona.nombre === zonacita));
          zonasArr[indexZona].sesiones = zonasArr[indexZona].sesiones - 1;
        });
      });
      /*const totalPendiente = bono.sesiones - citasRest.length;
      bono.sesiones_pendientes = totalPendiente;
      if (totalPendiente === 0) {
        bono.estado = BonoConstants.FINALIZADO;
      } else {
        bono.estado = BonoConstants.ACTIVO;
      }*/
      bono.zonas = JSON.stringify(zonasArr);
      this.bonoService.addOrUpdateBono(bono);
    });
  }
}
