/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable no-underscore-dangle */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActionSheetController, ModalController, ToastController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { Bono, Cita, Cliente, tipoBono, Zona } from 'src/app/interfaces/interfaces';
import { BonoService } from 'src/app/services/bono.service';
import { CitaService } from 'src/app/services/cita.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { ServiciosService } from 'src/app/services/servicios.service';
import { citasEstadosConstants } from '../cita/citas.estados.constants';
import { ModalGestionBonoComponent } from '../modal-gestion-bono/modal-gestion-bono.component';
import { BonoConstants } from './bono.constant';

@Component({
  selector: 'app-bono',
  templateUrl: './bono.component.html',
  styleUrls: ['./bono.component.scss'],
})
export class BonoComponent implements OnInit, OnChanges {

  @Input()
  bono: Bono;

  @Input()
  cliente: Cliente;

  @Input()
  activeMenu = true;

  @Input()
  showListCita = true;

  @Output()
  returEvento = new EventEmitter<string>();


  public editMode = false;

  public zonas: string[];

  public tipoBonos: tipoBono[];

  public zonasSelected: string[] = [];

  public zonasSelectedObj: Zona[] = [];

  public tipoBono: number;

  public nombreBono = '';

  public sesionesPendientes = 0;

  public citas: Cita[] = [];

  public zonasInicioSelectedObj: Zona[] = [];

  constructor(public actionSheetController: ActionSheetController,
    private modalCtrl: ModalController,
    private citaService: CitaService,
    private clienteService: ClienteService,
    private toastController: ToastController,
    private servicioService: ServiciosService,
    private bonoService: BonoService) { }


  ngOnInit() {
    this.zonas = BonoConstants.zonas;
    this.tipoBonos = BonoConstants.tipoBonos;
    if (!this.bono._id) {
      this.editMode = true;
    } else {
      this.cargarNombreBono();
      if (this.bono.tipo === '1') {
        this.descargarZonas();
      }
      this.updateCitas();
      // this.calcularSesionesPendientes();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    /*if (changes.bono) {
      this.zonas = BonoConstants.zonas;
      this.tipoBonos = BonoConstants.tipoBonos;
      if (!this.bono._id) {
        this.editMode = true;
      } else {
        this.cargarNombreBono();
        if (this.bono.tipo === '1') {
          this.descargarZonas();
        }
        this.updateCitas();
        // this.calcularSesionesPendientes();
      }
    }*/
  }



  /*private calcularSesionesPendientes() {
    this.bonoService.getSesionesPendientesByBono(this.bono).subscribe(resp => {
      this.sesionesPendientes = this.bono.sesiones - resp['citas'];
    });
  }*/

  async presentActionSheet() {
    const buttons = [];
    if (this.activeMenu) {
      buttons.push(
        {
          text: 'Detalle Bono',
          icon: 'calendar',
          handler: () => {
            this.gestionarBono();
          },
        }
      );
      if (this.bono.estado !== BonoConstants.FINALIZADO) {
        buttons.push(
          {
            text: 'Solicitar cita',
            icon: 'create',
            handler: () => {
              this.solicitarCita();
            },
          }
        );
      }
      const actionSheet = await this.actionSheetController.create({
        header: 'BONO',
        cssClass: 'my-custom-class',
        buttons,
      });

      await actionSheet.present();

      const { role } = await actionSheet.onDidDismiss();
    }
  }

  async guardar( fBono: NgForm ) {
    if ( fBono.invalid ) { console.log('FORMULARIO IiiiiiiiNVALIDO'); return; }
    if (this.bono.tipo === '1') {
      this.cargarZonas();
    }
    this.bono.cliente = this.cliente._id;
    this.bonoService.addOrUpdateBono(this.bono);
    this.cargarNombreBono();
    this.editMode = false;
    this.descargarZonas();
  }

  public cancelar(): void{
    this.editMode = false;
    // this.cliente = Object.assign({}, this.clienteCopia);

  }

  public updateCitas() {
    this.citaService.getCitasByBono(this.bono._id).subscribe(citas=> {
      this.citas = citas['citas'];
    });
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'CITA SOLICITADA CORRECTAMENTE',
      duration: 2000,
      position: 'middle',
      color: 'success'
    });

    await toast.present();
  }

  private descargarZonas() {
    this.zonasSelectedObj = JSON.parse(this.bono.zonas);
    this.zonasInicioSelectedObj = JSON.parse(this.bono.zonas_inicio);
    this.zonasSelectedObj.forEach(zona => {
      this.zonasSelected.push(zona.nombre);
    });
  }

  private cargarNombreBono() {
    this.nombreBono = BonoConstants.cargarNombreBono(this.bono.tipo);
  }

  private async gestionarBono(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalGestionBonoComponent,
      componentProps: {
        cliente: this.cliente,
        bono: this.bono,
        citas: this.citas,
        showClienteBono: false
      }
    });
    modal.onDidDismiss().then(res => {
      this.updateCitas();
    });
    return await modal.present();
  }

  private cargarZonas() {
    const zonas: Zona[] = [];
    this.zonasSelected.forEach(zona => {
      zonas.push({nombre:zona, sesiones:this.bono.sesiones});
    });
    this.bono.zonas = JSON.stringify(zonas);
  }

  private solicitarCita() {
    const nuevaCita: Cita = {bono: this.bono._id, estado: citasEstadosConstants.SOLICITUD};
    if (this.bono.tipo === '1') {
      nuevaCita.zonas = this.cargarZonasEnCita();
    }
    nuevaCita.cliente = this.cliente._id;
    this.servicioService.getServicioByTipoBono(this.bono.tipo).subscribe(resp => {
      nuevaCita.servicio = resp['servicio']?._id;
      this.citaService.addOrUpdateCita(nuevaCita, this.bono);
      this.presentToast();
      this.returEvento.emit('citaSolicitada');
    });
  }
  private cargarZonasEnCita(): string {
    const zonasObj: Zona[] = JSON.parse(this.bono.zonas);
    const zonas: string[] = [];
    zonasObj.forEach(zona =>{
      if(zona.sesiones > 0) {
        zonas.push(zona.nombre);
      }
    });
    return JSON.stringify(zonas);
  }

}
