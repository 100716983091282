import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tarjeta',
  templateUrl: './tarjeta.component.html',
  styleUrls: ['./tarjeta.component.scss'],
})
export class TarjetaComponent implements OnInit {

  @Input()
  public title = '';

  @Input()
  public subtitle = '';

  @Input()
  public type = 'black';

  @Input()
  public icon;

  @Input()
  public image = '';

  @Input()
  public selected = false;

  constructor() { }

  ngOnInit() {}

}
