/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { tipoBono } from 'src/app/interfaces/interfaces';

export class BonoConstants {
    static readonly zonas: string[] = [
        'Piernas completas',
        'Medias piernas',
        'Pies',
        'Pubis',
        'Ingles normales',
        'Ingles completas',
        'Intergluteos',
        'Axilas',
        'Brazos',
        'Medios Brazos',
        'Manos',
        'Facial completo',
        'Labio superior',
        'Menton',
        'Patillas',
        'Pomulos',
        'Entrecejo',
        'Linea alba',
        'Entrepecho',
        'Areolas',
        'Gluteos',
        'Espalda',
        'Lumbar',
        'Pecho',
        'Abdomen',
        'Hombros',
        'Nuca',
        'Cuello',
        'Orejas'
    ];

    static readonly ACTIVO = 'ACTIVO';
    static readonly FINALIZADO = 'FINALIZADO';

    static readonly tipoBonos: tipoBono[] = [
        {id: '1', nombre: 'Depilación Láser'},
        {id: '2', nombre: 'Rayos UVA'},
        {id: '3', nombre: 'Presoterapia'},
        {id: '4', nombre: 'Electromusculación'},
        {id: '5', nombre: 'Diatermia Corporal'},
        {id: '6', nombre: 'Diatermia Facial'},
        {id: '7', nombre: 'Mesoterapia Corporal'},
        {id: '8', nombre: 'Mesoterapia Facial'},
        {id: '9', nombre: 'Dermapen'},
        {id: '10', nombre: 'Tto Cosmético Facial'},
        {id: '11', nombre: 'Tto Cosmético Corporal'}
    ];

    public static cargarNombreBono(idTipoBono: string): string {
        const tipoBono = BonoConstants.tipoBonos.find(b => b.id === idTipoBono);
        return tipoBono.nombre;
    }
}
