import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Promocion } from 'src/app/interfaces/interfaces';
import { PromocionService } from 'src/app/services/promocion.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-promocion',
  templateUrl: './promocion.component.html',
  styleUrls: ['./promocion.component.scss'],
})
export class PromocionComponent implements OnInit {

  @Input()
  public promo: Promocion;

  public urlBaseImage = environment.url+'/promociones/';

  constructor() { }

  ngOnInit() {}

}
