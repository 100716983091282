/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ClienteService } from './cliente.service';
import { Notificacion, Servicio } from '../interfaces/interfaces';

const URL = environment.url;


@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

  constructor( private http: HttpClient,
    private usuarioService: ClienteService) { }

  public getNotificacionesByCliente() {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Notificacion[]>(`${ URL }/push/${ this.usuarioService.getUsuario()._id }`, {headers});
  }

  public notificacionAbierta(idNotif: string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.post<Notificacion>(`${ URL }/push/leida/${ idNotif }`,{},{headers}).subscribe(resp => {
    }), error => {
      console.log(error);
    };
  }

  public getNotificacionesByClienteNoLeidas() {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Notificacion[]>(`${ URL }/push/noleidas/${ this.usuarioService.getUsuario()._id }`, {headers});
  }

}
