/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/dot-notation */
import { Component, OnInit, Input } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Notificacion } from 'src/app/interfaces/interfaces';
import { ClienteService } from 'src/app/services/cliente.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input()
  showBackButton = false;

  @Input()
  public title = '';

  @Input()
  public icon = '';

  public notificaciones: Notificacion[] = [];

  constructor( private notifiService: NotificacionesService,
    private navCtrl: NavController) { }

  ngOnInit() {
    this.notifiService.getNotificacionesByClienteNoLeidas().subscribe(resp => {
      this.notificaciones = resp['notificaciones'];
    });
  }

  public verNotificaciones() {
    this.navCtrl.navigateRoot('/notificaciones').then(() => {
      /*this.notificaciones.forEach(n => {
        this.notifiService.notificacionAbierta(n._id);
      });*/
      this.notificaciones = [];
    });
  }

}
