import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UsuarioGuard } from './guards/usuario.guard';

const routes: Routes = [
  {
    path:'',
    redirectTo: 'inicio',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/change-password/change-password.module').then( m => m.ChangePasswordPageModule),
  },
  {
    path: 'inicio', loadChildren: () => import('./pages/inicio/inicio.module').then( m => m.InicioPageModule),
    canLoad:[ UsuarioGuard ]
  },
  {
    path: 'citas', loadChildren: () => import('./pages/citas/citas.module').then( m => m.CitasPageModule),
    canLoad:[ UsuarioGuard ]
  },
  {
    path: 'propuestas-citas', loadChildren: () =>
      import('./pages/propuestas-citas/propuestas-citas.module').then( m => m.PropuestaCitasPageModule),
    canLoad:[ UsuarioGuard ]
  },
  {
    path: 'bonos', loadChildren: () => import('./pages/bonos/bonos.module').then( m => m.BonosPageModule),
    canLoad:[ UsuarioGuard ]
  },
  {
    path: 'promociones',
    loadChildren: () => import('./pages/promociones/promociones.module').then( m => m.PromocionesPageModule),
    canLoad:[ UsuarioGuard ]
  },
  {
    path: 'pedir-cita',
    loadChildren: () => import('./pages/pedir-cita/pedir-cita.module').then( m => m.PedirCitaPageModule),
    canLoad:[UsuarioGuard]
  },
  {
    path: 'notificaciones',
    loadChildren: () => import('./pages/notificaciones/notificaciones.module').then( m => m.NotificacionesPageModule),
    canLoad:[UsuarioGuard]
  },
  {
    path: 'mis-datos',
    loadChildren: () => import('./pages/mis-datos/mis-datos.module').then( m => m.MisDatosPageModule),
    canLoad:[UsuarioGuard]
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
