import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ClienteService } from './cliente.service';
import { Servicio } from '../interfaces/interfaces';

const URL = environment.url;


@Injectable({
  providedIn: 'root'
})
export class ServiciosService {

  constructor( private http: HttpClient,
    private usuarioService: ClienteService) { }

  public getServiciosByArea(area: number) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Servicio[]>(`${ URL }/servicios/${ area }`, {headers});
  }

  public getServicioByTipoBono(tipoBono: string) {
    const headers = new HttpHeaders({
      'x-token': this.usuarioService.token
    });
    return this.http.get<Servicio>(`${ URL }/servicios/bono/${ tipoBono }`, {headers});
  }

  }
