import { Component, OnInit, Input } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { ClienteService } from 'src/app/services/cliente.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  @Input()
  showBackButton = false;

  constructor(
      private navCtrl: NavController,
      private menu: MenuController,
      private usuarioService: ClienteService
    ) { }

  ngOnInit() {}

  public goToUrl(url: string) {
    this.navCtrl.navigateRoot( '/' + url, { animated: true } );
    this.menu.close('principal');
  }

  public contacto() {
    window.location.href = 'https://wa.me/34672132101';
  }

}
