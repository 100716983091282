import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { CitaComponent } from './cita/cita.component';
import { BonoComponent } from './bono/bono.component';
import { ModalGestionBonoComponent } from './modal-gestion-bono/modal-gestion-bono.component';
import { PromocionComponent } from './promocion/promocion.component';
import { TarjetaComponent } from './tarjeta/tarjeta.component';

@NgModule({
  entryComponents:[
  ],
  declarations: [
    HeaderComponent,
    MenuComponent,
    CitaComponent,
    BonoComponent,
    ModalGestionBonoComponent,
    PromocionComponent,
    TarjetaComponent
  ],
  exports: [
    HeaderComponent,
    MenuComponent,
    CitaComponent,
    BonoComponent,
    ModalGestionBonoComponent,
    PromocionComponent,
    TarjetaComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ]
})
export class ComponentsModule { }
